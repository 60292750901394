import TPoint from '@/helpers/objects/points/point'

export default {

    state: {

        statePoints: false
    },

    getters: {

        getPoints: state => state.statePoints
    },

    actions: {
        
        addPoints ( {state}, points) {

            let dispPoints = [],
                dispObjects = this.getters.getObjects

            for(let point of points) {

                point = new TPoint( point ) 

                for(let obj of dispObjects) {
                    if( obj.id == point.id ) {
                        obj.calcOnline(point)
                    }
                }
                
                dispPoints.push(point)
            }

            state.statePoints = dispPoints
        },

        addPoint( {state}, newPoint) {

            const points = this.getters.getPoints
            const objects = this.getters.getObjects

            if(objects && points) {

                let point = points.filter( point => point.id == newPoint.id )[0]

                point.add(newPoint)

                objects.map( obj => obj.id == point.id ? obj.calcOnline(point) : false )

                state.statePoints = points
            }
        }
    }
}
