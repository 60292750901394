<template>
    <div id="app">
        <component :is="layout">
            <router-view />
        </component>
    </div>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'

import { mapGetters, mapMutations, mapActions } from 'vuex'

import { Base64 } from 'js-base64'

// Список команд для подписки
import { userFilter } from '@/helpers/command_list'

export default {
    name: 'App',
    components: {
        EmptyLayout,
        MainLayout
    },
    data: () => ({
        socketPing: false
    }),
    computed: {
        ...mapGetters([
            'getSocket',
            'isAuth',
            'getPoints', 
            'getTrackCnt',
            'getUser'
        ]),
        layout () {
            return (this.$route.meta.layout || 'empty') + '-layout'
        }
    },
    methods: {
        ...mapMutations([
            'updateUserMsg', 
            'setSocketIsLoading',
            'setAuthProcess'
        ]),
        ...mapActions([
            'addSocket',
            'addUser',
            'addObjects',
            'addPoints',
            'addSensors',
            'addPoint',
            'addTrack',
            'addDiag'
        ]),
        
        openSocket(_this) {
            const socket = new WebSocket('wss://disp.shatl-t.ru')

            socket.onopen = function() {
                _this.setSocketIsLoading(false)

                _this.addSocket(socket)

                if (_this.isAuth) {
                    const user = {
                        lo: localStorage.getItem('DISP_L'),
                        po: localStorage.getItem('DISP_P')
                    }
            
                    socket.send('ul' + Base64.encode(JSON.stringify(user)))
                }

                _this.socketPing = setInterval(function() {
                    socket.send('pp')
                }, 50000)
            }

            socket.onmessage = function(msg) {
                const cmd = msg.data.slice(0,2)

                let body

                if (msg.data.slice(2)) {
                    body = JSON.parse( Base64.decode( msg.data.slice(2) ) )
                } else {
                    body = null
                }

                switch(cmd) {

                    case 'ul':
                        _this.addUser(body)

                        socket.send('uf' + Base64.encode( userFilter ) ) // Подписка на команды

                        if (!_this.isAuth) {
                            _this.setAuthProcess(true)
                        }
                        break;

                    case 'uf':
                        socket.send('oi') // Получаем объекты
                        break;

                    case 'oi':
                        _this.addObjects(body)
                        socket.send('pi') // Получаем свойства объектов
                        break

                    case 'ou':
                        // Обновление объектов
                        break

                    case 'pi':
                        _this.addPoints(body)
                        socket.send('si') // Получаем датчики
                        break

                    case 'pu':
                        _this.addPoint(body)
                        break

                    case 'ps':
                        _this.addTrack(body)
                        console.log( 'Маршрут получен: ', new Date().toLocaleString() )
                        break

                    case 'si':
                        _this.addSensors(body)
                        break

                    case 'su':
                        // Обновления датчиков
                        break

                    case 'dj':
                    case 'de':
                        _this.addDiag(body)
                        break
                    
                    case 'ue':
                        console.log(body);
                        if(body.error == 'user_form_password_error' || body.error == 'login_or_password_not_found') {
                            localStorage.removeItem( 'DISP_L' )
                            localStorage.removeItem( 'DISP_P' )
                            socket.close()

                            _this.updateUserMsg('Ошибка: неверный логин/пароль')
                            
                        } else {
                            _this.updateUserMsg(body.error)
                        }
                        break;
                    
                    case 'oe':
                        console.log('Ошибка получения объектов', body)
                        break

                    case 'pe':
                        console.log('Ошибка получения свойств', body)
                        break

                }
            }

            socket.onclose = function() {
                _this.addSocket(false)

                _this.setSocketIsLoading(true)

                setTimeout(function() {
                    clearInterval(_this.socketPing)
                    _this.openSocket(_this)
                }, 5000)
            }
        }
    },
    watch: {
        getSocket() {
            if(!this.getSocket.readyState) {
                setTimeout(function() {
                    this.openSocket(this)
                }, 5000)
            }
        }
    },
    mounted () {
        this.openSocket(this)
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/layout.scss';
</style>
