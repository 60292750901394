import {TPointSimpleExtraACCELAA} from './point_simple_extra_accel_aa'
/**
 * показания акселерометр
 */
export class TPointSimpleExtraACCEL {
    /**
     * @returns {number}
     */
    get ATM() {
        return this._ATM;
    }

    /**
     * @param {number} value
     */
    set ATM(value) {
        this._ATM = value;
    }

    /**
     * @returns {Array.<TPointSimpleExtraACCELAA>}
     */
    get AA() {
        return this._AA;
    }

    /**
     * @param {Array.<TPointSimpleExtraACCELAA>} value
     */
    set AA(value) {
        this._AA = value;
    }

    /**
     * @param {undefined|{ATM: number, AA: Array.<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}} options
     */
    constructor(options) {
        /**
         * время проведения измерений первой передаваемой структуры показаний акселерометра (количество секунд с 00:00:00 01.01.2010 UTC)
         * @type {number}
         * @private
         */
        this._ATM = 0;
        /**
         * структуры данных показаний акселерометра
         * @type {Array.<TPointSimpleExtraACCELAA>}
         * @private
         */
        this._AA = [];
        if (options !== undefined) {
            this.add(options);
        }
    }

    /**
     * @param {{ATM: number, AA: Array.<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}} d
     */
    add(d) {
        for (let key in d) {
            if (key in d) {
                switch (key) {
                    case 'ATM':
                        this.ATM = d[key];
                        break;
                    case 'AA':
                        this.AA = [];
                        for (let k in d[key]) {
                            if (k in d[key]) {
                                this.AA.push(new TPointSimpleExtraACCELAA(d[key][k]));
                            }
                        }
                        break;
                }
            }
        }
    }

    /**
     * @returns {{ATM: number, AA: Array.<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}}
     */
    valueOf() {
        /**
         * @type {Array.<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}
         */
        const ret = [];
        this.AA.forEach((a) => ret.push(a.valueOf()));
        return {
            ATM: this._ATM,
            AA: ret
        }
    }
}