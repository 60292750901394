import TFormula from '@/helpers/objects/formula/formula'
import TPoints from '@/helpers/objects/points/points'

export default {

    state: {

        stateObject: {
            
            data: {
                name: ' ',
                online: {
                    descr: ''
                }
            },
            point: {
                n: {
                    Ntm: 0,
                    Lat: 0,
                    Lon: 0
                }
            },
            sensors: []
        }
    },

    getters: {

        getObject: state => state.stateObject
    },

    actions: {

        addObject ( {state}, objectID ) {

            function filteredSensor(filter) {
                let filterBlocks = filter.replace('&amp;', '&').replace('&gt;', '>').replace('&lt;', '<').split(';')
                for(let step of filterBlocks) {
                    let condition = step.split('??')
                    if( eval(condition[0]) ) {
                        return condition[1]
                    }
                }
                return false
            }

            let dispObject = {
                data: {
                    name: ' ',
                    online: {
                        descr: ''
                    }
                },
                point: {
                    n: {
                        Ntm: 0,
                        Lat: 0,
                        Lon: 0
                    }
                },
                sensors: []
            }

            if (objectID) {

                const objects = this.getters.getObjects
                const points  = this.getters.getPoints
                const sensors = this.getters.getSensors

                dispObject.data  = objects.filter(object => object.id == objectID)[0] // Получаем объект TObject
                dispObject.point = points.filter(point => point.id == objectID)[0]    // Получаем объект TPoint

                for (let sensor of sensors) {

                    if (sensor.object_id == objectID ) {

                        if(sensor.is_show) {

                            let sensorFormula   = '',
                                sensorValue     = '',
                                sensorUnit      = '',
                                sensorGroup     = ''

                            if(sensor.ext.formula) {
                                sensorFormula = new TFormula( sensor.ext.formula ).parse() // Распарсили формулу
                            }

                            sensorValue = new TPoints().eval(dispObject.point, sensorFormula) // Готовые значения датчиков
                            if(sensorValue === undefined) {
                                sensorValue = ''
                            } else {

                                switch(sensor.type) {
                                    case 1:
                                    case 2:
                                    case 3:
                                    case 4: 
                                        sensorGroup = 'digital'
                                        break;
                                    default: 
                                        sensorGroup = 'gauges'
                                }
                                
                                if( sensorGroup == 'digital' ) {
                                    let sensorValues = sensor.unit.split('/') // Если булевое значение датчика - вкл/выкл, откр/закр и т.п.
                                    sensorValue == 1 ? sensorValue = sensorValues[0] : sensorValue = sensorValues[1]
                                } else if(sensorValue !== 'н/о') {

                                    // [!] Внутри desc может быть и фильтр и произвольный текст
                                    // Проверяем есть ли в desc подстрока с фильтром

                                    let hasFilter = sensor.desc.indexOf('filter:')
                                    let hasEnd = sensor.desc.indexOf(':end')

                                    if(hasFilter >= 0 && hasEnd > 0) {

                                        let filter = sensor.desc
                                            .slice( hasFilter + 7, hasEnd )
                                            .replace(/REZ/g, sensorValue)

                                        let filterValue
                                        try {
                                            filterValue = filteredSensor(filter)
                                        } catch {
                                            filterValue = sensorValue
                                        }

                                        if(filterValue) {
                                            sensorValue = filterValue
                                        } else {
                                            if( Number(sensorValue) ) {
                                                sensorValue = Number(sensorValue.toFixed(2))
                                            }
                                            sensorUnit = sensor.unit
                                        }

                                    } else {
                                        sensorValue = Number(sensorValue.toFixed(2))
                                        sensorUnit = sensor.unit
                                    }
                                }
                            }

                            dispObject.sensors.push({
                                id:     sensor.id,
                                name:   sensor.name,
                                value:  sensorValue,
                                unit:   sensorUnit
                            }) 
                        }
                    }
                }

                dispObject.sensors.sort(function(a, b) {
                    if (a.name > b.name) return 1
                    if (a.name < b.name) return -1
                    return 0
                })
            }
            
            state.stateObject = dispObject
        }
    },
    
    mutations: {

        updateObject (state, objectData) {

            state.stateObject = objectData
        }
    }
}