<template>
    <div class="dispUser">
        <div v-if=" getUser" class="dispUser__info">
            <div class="dispUser__info dispUser__info--name">{{ getUser.fi }}</div>
            <div 
                class="dispUser__info dispUser__info--status" 
                :class="getSocket.readyState == 1 ? 'dispUser__info--online' : 'dispUser__info--offline'"
            >
                {{ getSocket.readyState == 1 ? 'Онлайн' : 'Оффлайн' }}
            </div>
        </div>
        <button class="dispUser__logout" @click="logout">
            <img src="@/assets/images/icons/icon-logout.png" alt="Выйти" title="Выйти">
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'DispUser',
    computed: {
        ...mapGetters(['getSocket', 'getUser'])
    },
    methods: {
        logout() {
            localStorage.removeItem('DISP_L')
            localStorage.removeItem('DISP_P')
            localStorage.removeItem('MAP_ZOOM_LVL')
            sessionStorage.removeItem('dispSensors')
            this.$router.push('/auth')
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/dispUser';
</style>
