/**
 * элемент формулы
 */
export class TFormulaElement {
    /**
     * @returns {number}
     */
    get bit() {
        return this._bit;
    }

    /**
     * @param {number} value
     */
    set bit(value) {
        this._bit = Number(value);
    }

    /**
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * @param {string} value
     */
    set name(value) {
        this._name = String(value);
    }

    /**
     * @returns {string}
     */
    get role() {
        return this._role;
    }

    /**
     * @param {string} value
     */
    set role(value) {
        this._role = String(value);
    }

    /**
     * @returns {boolean}
     */
    get pred() {
        return this._pred;
    }

    /**
     * @param {boolean} value
     */
    set pred(value) {
        this._pred = Boolean(value);
    }

    /**
     * @constructor
     * @param {{b: number, n: string, r: string, p: boolean}|undefined} obj
     */
    constructor(obj) {
        /**
         * номер бита или значение для констант
         * @type {number}
         * @private
         */
        this._bit = 0.0;
        /**
         * имя элемента
         * @type {string}
         * @private
         */
        this._name = "";
        /**
         * роль элемента в формуле
         * @type {string}
         * @private
         */
        this._role = "";
        /**
         * старое или новое значение
         * @type {boolean}
         * @private
         */
        this._pred = false;
        if (typeof obj === "object") {
            this.add(obj);
        }
    }

    /**
     * @param {{b: number, n: string, r: string, p: boolean}} d
     */
    add(d) {
        for (let key in d) {
            if (key in d) {
                switch (key) {
                    case "b":
                        this.bit = d[key];
                        break;
                    case "n":
                        this.name = d[key];
                        break;
                    case "r":
                        this.role = d[key];
                        break;
                    case "p":
                        this.pred = d[key];
                        break;
                }
            }
        }
    }

    /**
     * @returns {{b: number, n: string, r: string, p: boolean}}
     */
    valueOf() {
        return {
            b: this.bit,
            n: this.name,
            r: this.role,
            p: this.pred
        };
    }
}
/**
 * элемент дерева
 */
export class TFormulaExpression {
    /**
     * @returns {TFormulaElement}
     */
    get token() {
        return this._token;
    }

    /**
     * @returns {Number}
     */
    get size() {
        return this._args.length;
    }

    /**
     * @returns {TFormulaExpression}
     */
    get a() {
        return this._args[0];
    }

    /**
     * @returns {TFormulaExpression}
     */
    get b() {
        return this._args[1];
    }
    /**
     *
     * @param {TFormulaElement} token
     * @param {TFormulaExpression} a
     * @param {TFormulaExpression} b
     */
    constructor(token, a, b) {
        /**
         * элемент операция или датчик
         * @type {TFormulaElement}
         */
        this._token = new TFormulaElement(undefined);
        /**
         * аргументы операции
         * @type {Array.<TFormulaExpression>}
         */
        this._args = [];
        //Конструктор для элементов и датчиков
        if (token) {
            this._token = token;
        }
        // Конструктор унарных операций
        if (a) {
            this._args[0] = a;
        }
        // Конструктор бинарных операций
        if (b) {
            this._args[1] = b;
        }
    }
}
