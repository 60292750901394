<template>
    <main class="main">
        <DispUser />

        <router-view />
    </main>
</template>

<script>
import DispUser from '@/components/DispUser'

export default {
    components: {
        DispUser
    }
}
</script>