import {TPointSimple} from './point_simple';

export default class TPoint {
    /**
     * @param {TPointSimple} value
     */
    set o(value) {
        this._o = value;
    }

    /**
     * @param {TPointSimple} value
     */
    set n(value) {
        this._n = value;
    }

    /**
     * @returns {TPointSimple}
     */
    get o() {
        return this._o;
    }

    /**
     * @returns {TPointSimple}
     */
    get n() {
        return this._n;
    }

    /**
     * @returns {number}
     */
    get id() {
        return this.n.Id;
    }


    /**
     * @param {undefined|{id: number, lon: number, lat: number, spd: number, dir: number, alt: number, ntm: number, stm: number, vld: boolean, odm: number, bbb: boolean, din: number, mov: boolean, src: number, val: number, idd: number, icc: number, z: Array.<Number>, ext: {EXT_POS_DATA: {VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}, STATE_DATA: {STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}, AD_SENS_DATA: {DOUT: {string: number}, DIO: {string: number}, ANS: {string: number}}, COUNTERS_DATA: {string: number}, LOOPIN_DATA: {string: number}, ACCEL_DATA: {ATM: number, AA: Array.<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}}}} options
     */
    constructor(options) {
        /**
         * previous point
         * @type {TPointSimple}
         * @private
         */
        this._o = new TPointSimple(undefined);
        /**
         * current point
         * @type {TPointSimple}
         * @private
         */
        this._n = new TPointSimple(undefined);

        if (options !== undefined) {
            this.add(options);
        }
    }

    /**
     * @param {{id: number, lon: number, lat: number, spd: number, dir: number, alt: number, ntm: number, stm: number, vld: boolean, odm: number, bbb: boolean, din: number, mov: boolean, src: number, val: number, idd: number, icc: number, z: Array.<Number>, ext: {EXT_POS_DATA: {VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}, STATE_DATA: {STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}, AD_SENS_DATA: {DOUT: {string: number}, DIO: {string: number}, ANS: {string: number}}, COUNTERS_DATA: {string: number}, LOOPIN_DATA: {string: number}, ACCEL_DATA: {ATM: number, AA: Array.<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}}}} d
     */
    add(d) {
        this.o = this.n;
        this.n = new TPointSimple(d);
        //this._o.add(this._n.valueOf());
        //this._n.add(d);
        // const n = new TPointSimple(d);
        // this._o = this._n;
        // this._n = n;
    }

    /**
     *
     * @return {{c: {id: number, lon: number, lat: number, spd: number, dir: number, alt: number, ntm: number, stm: number, vld: boolean, odm: number, bbb: boolean, din: number, mov: boolean, src: number, val: number, idd: number, icc: number, z: Array.<Number>, ext: {EXT_POS_DATA: {VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}, STATE_DATA: {STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}, AD_SENS_DATA: {DOUT: {string: number}, DIO: {string: number}, ANS: {string: number}}, COUNTERS_DATA: {string: number}, LOOPIN_DATA: {string: number}, ACCEL_DATA: {ATM: number, AA: Array.<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}}}, o: {id: number, lon: number, lat: number, spd: number, dir: number, alt: number, ntm: number, stm: number, vld: boolean, odm: number, bbb: boolean, din: number, mov: boolean, src: number, val: number, idd: number, icc: number, z: Array.<Number>, ext: {EXT_POS_DATA: {VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}, STATE_DATA: {STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}, AD_SENS_DATA: {DOUT: {string: number}, DIO: {string: number}, ANS: {string: number}}, COUNTERS_DATA: {string: number}, LOOPIN_DATA: {string: number}, ACCEL_DATA: {ATM: number, AA: Array.<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}}}}}
     */
    valueOf() {
        return {
            c: this.n.valueOf(),
            o: this.o.valueOf(),
        }
    }

}