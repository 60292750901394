/**
 * местоположение
 */
export class TPointSimpleExtraPos {
    /**
     * @returns {number}
     */
    get VDOP() {
        return this._VDOP;
    }

    /**
     * @param {number} value
     */
    set VDOP(value) {
        this._VDOP = Number(value);
    }

    /**
     * @returns {number}
     */
    get HDOP() {
        return this._HDOP;
    }

    /**
     * @param {number} value
     */
    set HDOP(value) {
        this._HDOP = Number(value);
    }

    /**
     * @returns {number}
     */
    get PDOP() {
        return this._PDOP;
    }

    /**
     * @param {number} value
     */
    set PDOP(value) {
        this._PDOP = Number(value);
    }

    /**
     * @returns {number}
     */
    get SAT() {
        return this._SAT;
    }

    /**
     * @param {number} value
     */
    set SAT(value) {
        this._SAT = Number(value);
    }

    /**
     * @returns {number}
     */
    get NS() {
        return this._NS;
    }

    /**
     * @param {number} value
     */
    set NS(value) {
        this._NS = Number(value);
    }

    /**
     * @param {undefined|{VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}} options
     */
    constructor(options) {
        /**
         * снижение точности в вертикальной плоскости
         * (значение, умноженное на 100);
         * @type {number}
         * @private
         */
        this._VDOP = 0;
        /**
         * снижение точности в горизонтальной плоскости
         * (значение, умноженное на 100);
         * @type {number}
         * @private
         */
        this._HDOP = 0;
        /**
         * снижение точности по местоположению
         * (значение, умноженное на 100);
         * @type {number}
         * @private
         */
        this._PDOP = 0;
        /**
         * количество видимых спутников
         * @type {number}
         * @private
         */
        this._SAT = 0;
        /**
         * 0 - система не определена;
         * 1 - ГЛОНАСС;
         * 2 - GPS;
         * 4 - Galileo;
         * 8 - Compass;
         * 16 - Beidou;
         * 32 - DORIS;
         * 64 - IRNSS;
         * 128 - QZSS.
         * @type {number}
         * @private
         */
        this._NS = 0;
        if (options !== undefined) {
            this.add(options);
        }
    }

    /**
     * @param {{VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}} d
     */
    add(d) {
        for (let key in d) {
            if (key in d) {
                switch (key) {
                    case 'VDOP':
                        this.VDOP = Number(d[key]) / 100;
                        break;
                    case 'HDOP':
                        this.HDOP = Number(d[key]) / 100;
                        break;
                    case 'PDOP':
                        this.PDOP = Number(d[key]) / 100;
                        break;
                    case 'SAT':
                        this.SAT = Number(d[key]);
                        break;
                    case 'NS':
                        this.NS = Number(d[key]);
                        break;
                }
            }
        }
    }

    /**
     * @returns {{VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}}
     */
    valueOf() {
        return {
            VDOP: this.VDOP,
            HDOP: this.HDOP,
            PDOP: this.PDOP,
            SAT: this.SAT,
            NS: this.NS
        }
    }
}