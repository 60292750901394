export default {

    state: {

        stateTrack: null,
        stateTrackCnt: null
    },

    getters: {

        getTrack: state => state.stateTrack,
        getTrackCnt: state => state.stateTrackCnt
    },

    actions: {
    
        addTrack ( {state} , points) {

            let trackPoints = []

            if('coordinates' in points) {

                for(let point of points.coordinates) {

                    trackPoints.push([point[1], point[0]])
                }
            }

            state.stateTrack = trackPoints
        }
    }
}
