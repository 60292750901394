/**
 * состояние
 */
export class TPointSimpleExtraState {
    /**
     * @returns {number}
     */
    get STATE() {
        return this._STATE;
    }

    /**
     * @param {number} value
     */
    set STATE(value) {
        this._STATE = Number(value);
    }

    /**
     * @returns {number}
     */
    get MPSV() {
        return this._MPSV;
    }

    /**
     * @param {number} value
     */
    set MPSV(value) {
        this._MPSV = Number(value);
    }

    /**
     * @returns {number}
     */
    get BBV() {
        return this._BBV;
    }

    /**
     * @param {number} value
     */
    set BBV(value) {
        this._BBV = Number(value);
    }

    /**
     * @returns {number}
     */
    get IBV() {
        return this._IBV;
    }

    /**
     * @param {number} value
     */
    set IBV(value) {
        this._IBV = Number(value);
    }

    /**
     * @returns {number}
     */
    get FLAGS() {
        return this._FLAGS;
    }

    /**
     * @param {number} value
     */
    set FLAGS(value) {
        this._FLAGS = Number(value);
    }

    /**
     * @param {undefined|{STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}} options
     */
    constructor(options) {
        /**
         * 0.Пассивный 1.ЭРА 2.Активный 3.Экстренный вызов 4.Экстренное
         * @type {number}
         * @private
         */
        this._STATE = 0;
        /**
         * значение напряжения основного источника питания, B дискретность 0,1 В;
         * @type {number}
         * @private
         */
        this._MPSV = 0.0;
        /**
         * значение напряжения резервной батареи, B дискретностью 0,1 В;
         * @type {number}
         * @private
         */
        this._BBV = 0.0;
        /**
         * значение напряжения внутренней батареи, B с дискретностью 0,1 В;
         * @type {number}
         * @private
         */
        this._IBV = 0.0;
        /**
         * MPSV 0x4(Вкл/Выкл) IBV 0x2(Вкл/Выкл) BBV 0x1(Вкл/Выкл)
         * @type {number}
         * @private
         */
        this._FLAGS = 0;
        if (options !== undefined) {
            this.add(options);
        }
    }

    /**
     * @param {{STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}} d
     */
    add(d) {
        for (let key in d) {
            if (key in d) {
                switch (key) {
                    case 'STATE':
                        this.STATE = d[key];
                        break;
                    case 'MPSV':
                        this.MPSV = d[key];
                        break;
                    case 'BBV':
                        this.BBV = d[key];
                        break;
                    case 'IBV':
                        this.IBV = d[key];
                        break;
                    case 'FLAGS':
                        this.FLAGS = d[key];
                        break;
                }
            }
        }
    }

    /**
     * @returns {{STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}}
     */
    valueOf() {
        return {
            STATE: this.STATE,
            MPSV: this.MPSV,
            BBV: this.BBV,
            IBV: this.IBV,
            FLAGS: this.FLAGS
        }
    }
}