/**
 * список всех получаемых команд
 * @type {string}
 */
export const userFilter = JSON.stringify([
    // устройства
    // {
    //     c: "d1",
    //     p: true,
    // },
    // {
    //     c: "d2",
    //     p: true,
    // },
    // {
    //     c: "d3",
    //     p: true,
    // },
    // {
    //     c: "d4",
    //     p: true,
    // },
    // {
    //     c: "d5",
    //     p: true,
    // },
    // {
    //     c: "d6",
    //     p: true,
    // },
    // {
    //     c: "d7",
    //     p: true,
    // },
    // {
    //     c: "d8",
    //     p: true,
    // },
    // {
    //     c: "d9",
    //     p: true,
    // },
    // объекты
    {
        c: "oi",
        p: true
    },
    {
        c: "ou",
        p: true
    },
    {
        c: "od",
        p: true
    },
    // датчики
    {
        c: "si",
        p: true
    },
    {
        c: "sx",
        p: true
    },
    {
        c: "su",
        p: true
    },
    {
        c: "sd",
        p: true
    },
    // отчеты
    // {
    //     c: "ri",
    //     p: true
    // },
    // {
    //     c: "rx",
    //     p: true
    // },
    // {
    //     c: "ru",
    //     p: true
    // },
    // {
    //     c: "rd",
    //     p: true
    // },
    // {
    //     c: "rf",
    //     p: true
    // },
    // точки
    {
        c: "ti",
        p: true
    },
    {
        c: "pi",
        p: true
    },
    {
        c: "pu",
        p: true
    },
    {
        c: "ps",
        p: true
    },
    {
        c: "pt",
        p: true
    },
    // {
    //     c: "p2",
    //     p: true
    // },
    // {
    //     c: "p3",
    //     p: true
    // },
    // {
    //     c: "p4",
    //     p: true
    // },
    // {
    //     c: "p5",
    //     p: true
    // },
    // сообщения
    // {
    //     c: "ni",
    //     p: true
    // },
    // {
    //     c: "nx",
    //     p: true
    // },
    // {
    //     c: "nu",
    //     p: true
    // },
    // {
    //     c: "nd",
    //     p: true
    // },
    // {
    //     c: "nl",
    //     p: true
    // },
    // пользователей
    // {
    //     c: "u1",
    //     p: true
    // },
    {
        c: "uf",
        p: true
    },
    // {
    //     c: "u2",
    //     p: true
    // },
    // {
    //     c: "u3",
    //     p: true
    // },
    // {
    //     c: "u4",
    //     p: true
    // },
    // {
    //     c: "11",
    //     p: true,
    // },
    // журнала
    // {
    //     c: "ji",
    //     p: true
    // },
    // геозоны
    // {
    //     c: "g1",
    //     p: true
    // },
    // {
    //     c: "g2",
    //     p: true
    // },
    // {
    //     c: "g3",
    //     p: true
    // },
    {
        c: "dj",
        p: true
    },
    {
        c: "dj",
        p: true
    }
]);