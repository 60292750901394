import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: () => import('@/views/Auth.vue'),
        meta: {
            layout: 'empty',
            breadcrumb: []
        }
    },
    {
        path: '/',
        name: 'Main',
        component: () => import('@/views/Main.vue'),
        meta: {
            layout: 'main',
            auth: true,
            breadcrumb: []
        }
    },
    {
        path: '/car/:id',
        name: 'Car',
        component: () => import('@/views/Car.vue'),
        meta: {
            layout: 'main',
            auth: true,
            breadcrumb: []
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.afterEach(() => {
    window.scrollTo(0,0)
})

router.beforeEach((to, from, next) => {
    const requireAuth = to.matched.some(record => record.meta.auth)
    let isAuth = localStorage.getItem('DISP_L') && localStorage.getItem('DISP_P') ? true : false

    if (requireAuth && !isAuth) {
        next('/auth')
    } else {
        next()
    }
})

export default router
