import {TFormulaElement, TFormulaExpression} from "./element";

export const name = "FormulaElement";
/**
 * описывает формулу
 */
export default class TFormula {
    /**
     *
     * @param {undefined|Array.<{b: number, n: string, r: string, p: boolean}>} obj
     */
    constructor(obj) {
        /**
         *
         * @type {Array.<TFormulaElement>}
         * @private
         */
        this._data = [];

        if (obj !== undefined) {
            this.add(obj);
        }
    }

    /**
     * @param {Array.<{b: number, n: string, r: string, p: boolean}>} d
     */
    add(d) {
        this._data = [];
        try {
            d.forEach((e) => this._data.push(new TFormulaElement(e)));
        } catch (e) {
            console.log("TFormula Error:", e);
        }
    }

    /**
     * @returns {Array.<{b: number, n: string, r: string, p: boolean}>}
     */
    valueOf() {
        /**
         * @type {Array.<{b: number, n: string, r: string, p: boolean}>}
         */
        const ret = [];
        this._data.forEach((e) => ret.push(e.valueOf()));
        return ret;
    }

    /**
     * Парсер
     * @returns {TFormulaExpression}
     */
    parse() {
        /**
         * Кусок который еще не распарсили
         * @type {Array.<TFormulaElement>}
         */
        const input = this._data;
        /**
         * указатель на код
         * @type {number}
         */
        let index = 0;
        /**
         * Список всех известных токенов - операции и скобки.
         * @type {string[]}
         */
        //const tokens = ["+", "-", '|', '&', '!', "*", "^", "/", "%", ">", "<", "==", "!=", "(", ")"];
        /**
         * приоритет бинарной операции
         * @param {TFormulaElement} token
         * @returns {number}
         */
        const getPriority = (token) => {
            let ret = 0;
            // Возвращаем 0 если токен - это не бинарная операция (например ")")
            if (token instanceof TFormulaElement) {
                switch (token.name) {
                    case "!":
                        ret = 6;
                        break;
                    case "|":
                        ret = 7;
                        break;
                    case "^":
                        ret = 8;
                        break;
                    case "&":
                        ret = 9;
                        break;
                    case "==":
                    case "!=":
                        ret = 10;
                        break;
                    case ">":
                    case "<":
                        ret = 11;
                        break;
                    case "-":
                    case "+":
                        ret = 13;
                        break;
                    case "*":
                    case "/":
                    case "%":
                        ret = 14;
                        break;
                    default :
                        ret = 0;
                }
            }
            return ret;
        };
        /**
         * Парсит один токен
         * @returns {TFormulaElement|null}
         */
        const parse_token = () => {
            /**
             * @type {TFormulaElement|undefined}
             */
            const token = input[index];
            index++;
            if (token === undefined) {
                // Какой-то неизвестный токен, или символ '\0' - конец строки.
                return null;
            }
            return token;
        };
        /**
         * Парсит простое выражение
         * @returns {TFormulaExpression}
         */
        const parse_simple_expression = () => {
            // Парсим первый токен.
            let token = parse_token();
            if (token === null) {
                // Неожиданный конец строки, или неизвестный токен
                throw("Invalid input");
            }
            // это параметр или датчик возвращаем выражение без аргументов
            if (token.role === "params" || token.role === "sensors" || token.role === "geozones") {
                return new TFormulaExpression(token, undefined, undefined);
            }
            // Если это скобки, парсим и возвращаем выражение в скобках
            if (token.name === "(") {
                const result = parse();
                token = parse_token();
                if (token === null) {
                    // Неожиданный конец строки, или неизвестный токен
                    throw("Invalid input");
                }
                if (token.name !== ")") {
                    throw("Expected ')'");
                }
                return result;
            }
            // Иначе, это унарная операция
            return new TFormulaExpression(token, parse_simple_expression(), undefined);
        };
        /**
         * Парсит бинарное выражение
         * @param min_priority
         * @returns {TFormulaExpression}
         */
        const parse_binary_expression = (min_priority) => {
            // Парсим простое выражение.
            let left_expr = parse_simple_expression();
            // Повторяем цикл: парсинг операции, и проверка ее приоритета.
            for (; ;) {
                // Пробуем парсить бинарную операцию.
                const op = parse_token();
                const priority = getPriority(op);
                // Выходим из цикла если ее приоритет слишком низок
                // (или это не бинарная операция).
                if (priority <= min_priority) {
                    // Отдаем токен обратно,
                    index--;
                    // возвращаем выражение слева.
                    return left_expr;
                }
                // Парсим выражение справа.
                // Текущая операция задает минимальный приоритет.
                const right_expr = parse_binary_expression(priority);
                // Обновляем выражение слева.
                left_expr = new TFormulaExpression(op, left_expr, right_expr);
            }
        };
        /**
         * Основная функция парсинга
         * @returns {TFormulaExpression}
         */
        const parse = function () {
            return parse_binary_expression(0);
        };
        return parse();
    }

}