/**
 * дискретные и аналоговые входы/выходы
 */
export class TPointSimpleExtraADSENS {
    /**
     * @returns {number}
     */
    get DOUT() {
        return this._DOUT;
    }

    /**
     * @param {number} value
     */
    set DOUT(value) {
        this._DOUT = value;
    }

    /**
     * @returns {{string: number}}
     */
    get DIO() {
        return this._DIO;
    }

    /**
     * @param {{string: number}} value
     */
    set DIO(value) {
        this._DIO = value;
    }

    /**
     * @returns {{string: number}}
     */
    get ANS() {
        return this._ANS;
    }

    /**
     * @param {{string: number}} value
     */
    set ANS(value) {
        this._ANS = value;
    }

    /**
     * @param {undefined|{DOUT: number, DIO: {string: number}, ANS: {string: number}}} options
     */
    constructor(options) {
        /**
         * битовые флаги дискретных выходов
         * @type {number}
         * @private
         */
        this._DOUT = 0;
        /**
         * показания дополнительных дискретных входов
         * @type {{string:number}}
         * @private
         */
        this._DIO = {};
        /**
         * значение аналоговых датчиков
         * @type {{string:proto.pp.AnsValue}}
         * @private
         */
        this._ANS = {};
        if (options !== undefined) {
            this.add(options);
        }
    }

    /**
     * @param {{DOUT: number, DIO: {string: number}, ANS: {string: number}}} d
     */
    add(d) {
        for (let key in d) {
            if (key in d) {
                switch (key) {
                    // битовые флаги дискретных выходов
                    case 'DOUT':
                        this.DOUT = Number(d[key]);
                        break;
                    // показания дополнительных дискретных входов
                    case 'DIO':
                        this.DIO = d[key];
                        break;
                    // значение аналоговых датчиков
                    case 'ANS':
                        this.ANS = d[key];
                        break;
                }
            }
        }
    }

    /**
     * @returns {{DOUT: number, DIO: {string: number}, ANS: {string: number}}}
     */
    valueOf() {
        return {
            DOUT: this.DOUT,
            DIO: this.DIO,
            ANS: this.ANS
        }
    }
}