import {TPointSimpleExtraACCEL} from './point_simple_extra_accel'
import {TPointSimpleExtraADSENS} from './point_simple_extra_adsens'
import {TPointSimpleExtraCAN} from "./point_simple_extra_can";
import {TPointSimpleExtraPos} from './point_simple_extra_pos'
import {TPointSimpleExtraState} from './point_simple_extra_state'

/**
 * другие данные
 */
export class TPointSimpleExtra {
    /**
     * @returns {TPointSimpleExtraCAN}
     */
    get can() {
        return this._can;
    }

    /**
     *
     * @param {TPointSimpleExtraCAN} value
     */
    set can(value) {
        this._can = value;
    }

    /**
     * @param {{string: number}} value
     */
    set COUNTERS_DATA(value) {
        this._COUNTERS_DATA = value;
    }

    /**
     * @param {{string: number}} value
     */
    set LOOPIN_DATA(value) {
        this._LOOPIN_DATA = value;
    }

    /**
     * @returns {TPointSimpleExtraPos}
     */
    get EXT_POS_DATA() {
        return this._EXT_POS_DATA;
    }

    /**
     * @returns {TPointSimpleExtraState}
     */
    get STATE_DATA() {
        return this._STATE_DATA;
    }

    /**
     * @returns {TPointSimpleExtraADSENS}
     */
    get AD_SENS_DATA() {
        return this._AD_SENS_DATA;
    }

    /**
     * @returns {{string: number}}
     */
    get COUNTERS_DATA() {
        return this._COUNTERS_DATA;
    }

    /**
     * @returns {{string: number}}
     */
    get LOOPIN_DATA() {
        return this._LOOPIN_DATA;
    }

    /**
     * @returns {TPointSimpleExtraACCEL}
     */
    get ACCEL_DATA() {
        return this._ACCEL_DATA;
    }

    /**
     * @param {undefined|{EXT_POS_DATA: {VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}, STATE_DATA: {STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}, AD_SENS_DATA: {DOUT: number, DIO: {string: number}, ANS: {string: number}}, COUNTERS_DATA: {string: number}, LOOPIN_DATA: {string: number}, ACCEL_DATA: {ATM: number, AA: Array<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}, can: {can: {string: {}}}}} options
     */
    constructor(options) {
        /**
         * дополнительные данных определения местоположения
         * @type {TPointSimpleExtraPos}
         * @private
         */
        this._EXT_POS_DATA = new TPointSimpleExtraPos(undefined);
        /**
         * информации о  состоянии
         * @type {TPointSimpleExtraState}
         * @private
         */
        this._STATE_DATA = new TPointSimpleExtraState(undefined);
        /**
         * состоянии дополнительных дискретных и  аналоговых входов
         * @type {TPointSimpleExtraADSENS}
         * @private
         */
        this._AD_SENS_DATA = new TPointSimpleExtraADSENS(undefined);
        /**
         * данных  о значении  счетных входов
         * @type {{string: number}}
         * @private
         */
        this._COUNTERS_DATA = {};
        /**
         * состоянии шлейфовых входов
         * @type {{string: number}}
         * @private
         */
        this._LOOPIN_DATA = {};
        /**
         * ускорение
         * разрешающая способность полей ускорения - 0.01G.
         * @type {TPointSimpleExtraACCEL}
         * @private
         */
        this._ACCEL_DATA = new TPointSimpleExtraACCEL(undefined);
        /**
         * @type {TPointSimpleExtraCAN}
         * @private
         */
        this._can = new TPointSimpleExtraCAN(undefined);
        if (options !== undefined) {
            this.add(options);
        }
    }

    /**
     * @param {{EXT_POS_DATA: {VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}, STATE_DATA: {STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}, AD_SENS_DATA: {DOUT: number, DIO: {string: number}, ANS: {string: number}}, COUNTERS_DATA: {string: number}, LOOPIN_DATA: {string: number}, ACCEL_DATA: {ATM: number, AA: Array<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}, can: {can: {string: {}}}}} d
     */
    add(d) {        
        for (let key in d) {
            if (key in d) {
                switch (key) {
                    case 'EXT_POS_DATA':
                        this.EXT_POS_DATA.add(d[key]);
                        break;
                    case 'STATE_DATA':
                        this.STATE_DATA.add(d[key]);
                        break;
                    case 'AD_SENS_DATA':
                        this.AD_SENS_DATA.add(d[key]);
                        break;
                    case 'COUNTERS_DATA':
                        this.COUNTERS_DATA = d[key];
                        break;
                    case 'LOOPIN_DATA':
                        this.LOOPIN_DATA = d[key];
                        break;
                    case 'ACCEL_DATA':
                        this.ACCEL_DATA.add(d[key]);
                        break;
                    case 'can':
                        this.can.add(d[key]);
                        break;
                }
            }
        }
    }

    /**
     *
     * @returns {{EXT_POS_DATA: {VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}, STATE_DATA: {STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}, AD_SENS_DATA: {DOUT: number, DIO: {string: number}, ANS: {string: number}}, COUNTERS_DATA: {string: number}, LOOPIN_DATA: {string: number}, ACCEL_DATA: {ATM: number, AA: Array<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}, can: {can: {string: {}}}}}
     */
    valueOf() {
        return {
            EXT_POS_DATA: this.EXT_POS_DATA.valueOf(),
            STATE_DATA: this.STATE_DATA.valueOf(),
            AD_SENS_DATA: this.AD_SENS_DATA.valueOf(),
            COUNTERS_DATA: this.COUNTERS_DATA,
            LOOPIN_DATA: this.LOOPIN_DATA,
            ACCEL_DATA: this.ACCEL_DATA.valueOf(),
            can: this.can.valueOf(),
        }
    }
}