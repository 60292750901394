export default {

    state: {

        stateDiag: ''
    },

    getters: {

        getDiag: state => state.stateDiag
    },

    actions: {

        addDiag ( {state} , blocks) {

            if(Array.isArray(blocks)) {

                for(let block of blocks) {

                    switch(block.block_name) {

                        case 'ACU': block.block_name_ru = 'Блок управления подушками безопасности'
                            break
                        case 'APU': block.block_name_ru = 'Блок предпускового подогревателя'
                            break
                        case 'ATC': block.block_name_ru = 'Блок управления раздаточной коробкой'
                            break 
                        case 'BCM': block.block_name_ru = 'Центральный блок кузовной электроники'
                            break 
                        // case 'BCM_216': block.block_name_ru = ''
                        //     break 
                        case 'BMS':
                        case 'BMS_EN': block.block_name_ru = 'Высоковольтная батарея'
                            break 
                        // case 'BMS_NA': block.block_name_ru = ''
                        //     break 
                        case 'INC_KG': block.block_name_ru = 'Инвертор'
                            break 
                        // case 'INC_NA': block.block_name_ru = ''
                        //     break 
                        case 'CCU': block.block_name_ru = 'Блок управления климатом'
                            break 
                        case 'CDA': block.block_name_ru = 'Блок системы помощи водителю ADAS'
                            break 
                        case 'CGW': block.block_name_ru = 'Блок центрального интерфейса шин данных'
                            break 
                        case 'DCDC': block.block_name_ru = 'Преобразователь напряжения 320В/12В'
                            break 
                        case 'DCDC_INC_KG': block.block_name_ru = 'Преобразователь напряжения 320В/12В в инверторе'
                            break 
                        // case 'DCDC_INC_NA': block.block_name_ru = ''
                        //     break 
                        case 'DMFL': block.block_name_ru = 'Блок передней левой двери'
                            break 
                        case 'DMFR': block.block_name_ru = 'Блок передней правой двери'
                            break 
                        case 'DMRL': block.block_name_ru = 'Блок задней левой двери'
                            break 
                        case 'DMRR': block.block_name_ru = 'Блок задней правой двери'
                            break 
                        case 'DMS': block.block_name_ru = 'Блок переключателя режимов движения'
                            break 
                        case 'EMS': block.block_name_ru = 'Блок управления двигателем'
                            break 
                        case 'EPB': block.block_name_ru = 'Блок управления стояночным тормозом'
                            break 
                        case 'ERAG': block.block_name_ru = 'Блок системы ЭРА-ГЛОНАСС'
                            break 
                        case 'ESP': block.block_name_ru = 'Блок управления курсовой устойчивостью'
                            break 
                        case 'FCU': block.block_name_ru = 'Камера системы ADAS'
                            break 
                        case 'FRCU': block.block_name_ru = 'Передний радар системы ADAS'
                            break 
                        case 'HUD': block.block_name_ru = 'Проекционный дисплей'
                            break 
                        case 'IC': block.block_name_ru = 'Комбинация приборов'
                            break 
                        case 'MRRL': block.block_name_ru = 'Задний левый радар системы ADAS'
                            break 
                        case 'MRRR': block.block_name_ru = 'Задний правый радар системы ADAS'
                            break 
                        case 'OCUFL': block.block_name_ru = 'Передний левый датчик пассажира'
                            break 
                        case 'OCUFR': block.block_name_ru = 'Передний правый  датчик пассажира'
                            break 
                        case 'OCURL': block.block_name_ru = 'Задний левый датчик пассажира'
                            break 
                        case 'OCURR': block.block_name_ru = 'Задний правый датчик пассажира'
                            break 
                        case 'PACU': block.block_name_ru = 'Блок управления парктрониками'
                            break 
                        case 'PTG': block.block_name_ru = 'Блок открывания крышки багажника'
                            break 
                        case 'RCM': block.block_name_ru = 'Задний центральный блок мультимедии'
                            break 
                        case 'SCU': block.block_name_ru = 'Блок управления подвеской'
                            break 
                        case 'SMFL': block.block_name_ru = 'Блок управления передним левым сиденьем'
                            break 
                        case 'SMFR': block.block_name_ru = 'Блок управления передним правым сиденьем'
                            break 
                        case 'SMRL': block.block_name_ru = 'Блок управления задним левым сиденьем'
                            break 
                        case 'SMRR': block.block_name_ru = 'Блок управления задним правым сиденьем'
                            break 
                        case 'SVU': block.block_name_ru = 'Блок кругового обзора'
                            break 
                        case 'TCU': block.block_name_ru = 'Блок управления АКП'
                            break 
                        case 'TIM': block.block_name_ru = 'Блок прицепного устройства'
                            break 
                        case 'TMDTM': block.block_name_ru = 'Блок управления багажником'
                            break 
                        case 'VAU': block.block_name_ru = 'Блок бесключевого доступа'
                            break 
                    }

                    block.cnt = 0
                    if('val' in block) {
                        block.cnt = block.val.length
                    }
                }

                blocks.sort(function(a, b) {
                    if (a.block_name > b.block_name) return 1
                    if (a.block_name < b.block_name) return -1
                    return 0
                })
            }

            state.stateDiag = blocks
        }
    }
}
