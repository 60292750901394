export default {

    state: {

        stateSensors: false
    },

    getters: {

        getSensors: state => state.stateSensors
    },

    actions: {

        addSensors ( {state} , sensors) {

            sessionStorage.setItem('dispSensors', JSON.stringify( sensors ))
            state.stateSensors = sensors
        }
    }
}