import {TPointSimpleExtra} from './point_simple_extra'

const simpleKeysValue = [
    "STATE",
    "LON",
    "LAT",
    "SPD",
    "ALT",
    "ODM",
    "VDOP",
    "HDOP",
    "PDOP",
    "SAT",
    "MPSV",
    "BBV",
    "IBV",
    "MOV",
    "BBB",
];

/**
 * Точка
 */
export class TPointSimple {
    /**
     * @returns {TPointSimpleExtra}
     */
    get Ext() {
        return this._Ext;
    }

    /**
     * @returns {number}
     */
    get Id() {
        return this._Id;
    }

    /**
     * @param {number} value
     */
    set Id(value) {
        this._Id = Number(value);
    }

    /**
     * @returns {number}
     */
    get Lon() {
        return this._Lon;
    }

    /**
     * @param {number} value
     */
    set Lon(value) {
        this._Lon = Number(value);
    }

    /**
     * @returns {number}
     */
    get Lat() {
        return this._Lat;
    }

    /**
     * @param {number} value
     */
    set Lat(value) {
        this._Lat = Number(value);
    }

    /**
     * @returns {number}
     */
    get Spd() {
        return this._Spd;
    }

    /**
     * @param {number} value
     */
    set Spd(value) {
        this._Spd = Number(value);
    }

    /**
     * @returns {number}
     */
    get Dir() {
        return this._Dir;
    }

    /**
     * @param {number} value
     */
    set Dir(value) {
        this._Dir = Number(value);
    }

    /**
     * @returns {number}
     */
    get Alt() {
        return this._Alt;
    }

    /**
     * @param {number} value
     */
    set Alt(value) {
        this._Alt = Number(value);
    }

    /**
     * @returns {number}
     */
    get Ntm() {
        return this._Ntm;
    }

    /**
     * @param {number} value
     */
    set Ntm(value) {
        this._Ntm = Number(value);
    }

    /**
     * @returns {number}
     */
    get Stm() {
        return this._Stm;
    }

    /**
     * @param {number} value
     */
    set Stm(value) {
        this._Stm = Number(value);
    }

    /**
     * @returns {boolean}
     */
    get Vld() {
        return this._Vld;
    }

    /**
     * @param {boolean} value
     */
    set Vld(value) {
        this._Vld = Boolean(value);
    }

    /**
     * @returns {number}
     */
    get Odm() {
        return this._Odm;
    }

    /**
     * @param {number} value
     */
    set Odm(value) {
        this._Odm = Number(value);
    }

    /**
     * @returns {boolean}
     */
    get Bbb() {
        return this._Bbb;
    }

    /**
     * @param {boolean} value
     */
    set Bbb(value) {
        this._Bbb = Boolean(value);
    }

    /**
     * @returns {number}
     */
    get Din() {
        return this._Din;
    }

    /**
     * @param {number} value
     */
    set Din(value) {
        this._Din = Number(value);
    }

    /**
     * @returns {boolean}
     */
    get Mov() {
        return this._Mov;
    }

    /**
     * @param {boolean} value
     */
    set Mov(value) {
        this._Mov = Boolean(value);
    }

    /**
     * @returns {number}
     */
    get Src() {
        return this._Src;
    }

    /**
     * @param {number} value
     */
    set Src(value) {
        this._Src = Number(value);
    }

    /**
     * @returns {number}
     */
    get Val() {
        return this._Val;
    }

    /**
     * @param {number} value
     */
    set Val(value) {
        this._Val = Number(value);
    }

    /**
     * @returns {number}
     */
    get Idd() {
        return this._Idd;
    }

    /**
     * @param {number} value
     */
    set Idd(value) {
        this._Idd = Number(value);
    }

    /**
     * @returns {number}
     */
    get Icc() {
        return this._Icc;
    }

    /**
     * @param {number} value
     */
    set Icc(value) {
        this._Icc = Number(value);
    }

    /**
     * @returns {Array.<Number>}
     */
    get ZoneIn() {
        return this._ZoneIn;
    }

    /**
     * @param {Array.<Number>} value
     */
    set ZoneIn(value) {
        this._ZoneIn = value;
    }

    /**
     * @param {undefined|{id: number, lon: number, lat: number, spd: number, dir: number, alt: number, ntm: number, stm: number, vld: boolean, odm: number, bbb: boolean, din: number, mov: boolean, src: number, val: number, idd: number, icc: number, z: Array.<Number>, ext: {EXT_POS_DATA: {VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}, STATE_DATA: {STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}, AD_SENS_DATA: {DOUT: {string: number}, DIO: {string: number}, ANS: {string: number}}, COUNTERS_DATA: {string: number}, LOOPIN_DATA: {string: number}, ACCEL_DATA: {ATM: number, AA: Array.<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}}}} options
     */
    constructor(options) {
        /**
         * индикатор объекта
         * @type {number}
         * @private
         */
        this._Id = 0;
        /**
         * долгота в градусах - десятичный формат система координат WGS-84
         * @type {number}
         * @private
         */
        this._Lon = 0.0;
        /**
         * широта в градусах - десятичный формат система координат WGS-84
         * @type {number}
         * @private
         */
        this._Lat = 0.0;
        /**
         * скорость [км/ч]
         * @type {number}
         * @private
         */
        this._Spd = 0;
        /**
         * направление движения. Определяется как угол в градусах, который отсчитывается по часовой стрелке между северным направлением географического меридиана и направлением движения в точке измерения
         * @type {number}
         * @private
         */
        this._Dir = 0;
        /**
         * высота/глубина относительно уровня моря
         * @type {number}
         * @private
         */
        this._Alt = 0;
        /**
         * время навигации
         * @type {number}
         * @private
         */
        this._Ntm = 0;
        /**
         * Время получения данных
         * @type {number}
         * @private
         */
        this._Stm = 0;
        /**
         * Флаг достоверности данных
         * @type {boolean}
         * @private
         */
        this._Vld = false;
        /**
         * пройденное расстояние (пробег) в км
         * @type {number}
         * @private
         */
        this._Odm = 0;
        /**
         * битовый флаг, признак отправки данных из памяти ("черный ящик"):
         * @type {boolean}
         * @private
         */
        this._Bbb = false;
        /**
         * маска состояние цифровых входов с 1 по 32
         * @type {number}
         * @private
         */
        this._Din = 0;
        /**
         * Движение
         * @type {boolean}
         * @private
         */
        this._Mov = false;
        /**
         * определяет источник (событие), инициировавший посылку данной навигационной информации
         * @type {number}
         * @private
         */
        this._Src = 0;
        /**
         * данные, сопровождающие событие
         * @type {number}
         * @private
         */
        this._Val = 0;
        /**
         * идентификатор устройства
         * @type {number}
         * @private
         */
        this._Idd = 0;
        /**
         * пп номер точки
         * @type {number}
         * @private
         */
        this._Icc = 0;
        /**
         * другие данные данные
         * @type {TPointSimpleExtra}
         * @private
         */
        this._Ext = new TPointSimpleExtra(undefined);
        /**
         * список зон в которых точка находится или пересекла из
         * @type {Array.<Number>}
         * @private
         */
        this._ZoneIn = [];
        if (options !== undefined) {
            this.add(options);
        }
    }

    /**
     * @param {{id: number, lon: number, lat: number, spd: number, dir: number, alt: number, ntm: number, stm: number, vld: boolean, odm: number, bbb: boolean, din: number, mov: boolean, src: number, val: number, idd: number, icc: number, z: Array.<Number>, ext: {EXT_POS_DATA: {VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}, STATE_DATA: {STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}, AD_SENS_DATA: {DOUT: {string: number}, DIO: {string: number}, ANS: {string: number}}, COUNTERS_DATA: {string: number}, LOOPIN_DATA: {string: number}, ACCEL_DATA: {ATM: number, AA: Array.<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}}}} d
     */
    add(d) {
        for (let key in d) {
            if (key in d) {
                switch (key) {
                    // идентификатор объекта
                    case "id":
                        this.Id = Number(d[key]);
                        break;
                    // долгота в градусах - десятичный формат система координат WGS-84
                    case "lon":
                        this.Lon = parseFloat(d[key]);
                        break;
                    // широта в градусах - десятичный формат система координат WGS-84
                    case "lat":
                        this.Lat = parseFloat(d[key]);
                        break;
                    // скорость [км/ч]
                    case "spd":
                        this.Spd = Number(d[key]);
                        break;
                    // направление движения. Определяется как угол в градусах, который отсчитывается по часовой стрелке между северным направлением географического меридиана и направлением движения в точке измерения
                    case "dir":
                        this.Dir = Number(d[key]);
                        break;
                    // высота/глубина относительно уровня моря
                    case "alt":
                        this.Alt = Number(d[key]);
                        break;
                    // время навигации
                    case "ntm":
                        this.Ntm = Number(d[key]);
                        break;
                    // Время получения данных
                    case "stm":
                        this.Stm = Number(d[key]);
                        break;
                    // Флаг достоверности данных
                    case "vld":
                        this.Vld = (d[key] === true);
                        break;
                    // пройденное расстояние (пробег) в км
                    case "odm":
                        this.Odm = Number(d[key]);
                        break;
                    // битовый флаг, признак отправки данных из памяти ("черный ящик"):
                    case "bbb":
                        this.Bbb = (d[key] === true);
                        break;
                    // маска состояние цифровых входов с 1 по 32
                    case "din":
                        this.Din = Number(d[key]);
                        break;
                    // Движение
                    case "mov":
                        this.Mov = (d[key] === true);
                        break;
                    // определяет источник (событие), инициировавший посылку данной навигационной информации
                    case "src":
                        this.Src = Number(d[key]);
                        break;
                    // данные, сопровождающие событие
                    case "val":
                        this.Val = Number(d[key]);
                        break;
                    // идентификатор устройства
                    case "idd":
                        this.Idd = Number(d[key]);
                        break;
                    // пп номер точки
                    case "icc":
                        this.Icc = Number(d[key]);
                        break;
                    // список зон в которых точка находится или пересекла из
                    case "z":
                        this.ZoneIn = d[key] || [];
                        break;
                    // другие данные данные
                    case "ext":
                        this.Ext.add(d[key]);
                        break;
                }
            }
        }
    }

    /**
     * @returns {{id: number, lon: number, lat: number, spd: number, dir: number, alt: number, ntm: number, stm: number, vld: boolean, odm: number, bbb: boolean, din: number, mov: boolean, src: number, val: number, idd: number, icc: number, z: Array.<Number>, ext: {EXT_POS_DATA: {VDOP: number, HDOP: number, PDOP: number, SAT: number, NS: number}, STATE_DATA: {STATE: number, MPSV: number, BBV: number, IBV: number, FLAGS: number}, AD_SENS_DATA: {DOUT: {string: number}, DIO: {string: number}, ANS: {string: number}}, COUNTERS_DATA: {string: number}, LOOPIN_DATA: {string: number}, ACCEL_DATA: {ATM: number, AA: Array.<{RTM: number, XAAV: number, YAAV: number, ZAAV: number}>}}}}
     */
    valueOf() {
        return {
            id: this.Id,
            lon: this.Lon,
            lat: this.Lat,
            spd: this.Spd,
            dir: this.Dir,
            alt: this.Alt,
            ntm: this.Ntm,
            stm: this.Stm,
            vld: this.Vld,
            odm: this.Odm,
            bbb: this.Bbb,
            din: this.Din,
            mov: this.Mov,
            src: this.Src,
            val: this.Val,
            idd: this.Idd,
            icc: this.Icc,
            z: this.ZoneIn,
            ext: this.Ext.valueOf()
        }
    }

    /**
     * вернет значение по имени простого параметра
     *
     * @param {string} key
     * @returns {number}
     */
    getValueSimple(key) {
        let ret = 0;
        switch (key) {
            case "STATE":
                ret = this.Ext.STATE_DATA.STATE;
                break;
            case "LON":
                ret = this.Lon;
                break;
            case "LAT":
                ret = this.Lat;
                break;
            case "SPD":
                ret = this.Spd;
                break;
            case "DIR":
                ret = this.Dir;
                break;
            case "ALT":
                ret = this.Alt;
                break;
            case "NTM":
                ret = this.Ntm;
                break;
            case "STM":
                ret = this.Stm;
                break;
            case "ODM":
                ret = this.Odm;
                break;
            case "BBB":
                ret = this.Bbb === true ? 1 : 0;
                break;
            case "MOV":
                ret = this.Mov === true ? 1 : 0;
                break;
            case "VDOP":
                ret = this.Ext.EXT_POS_DATA.VDOP;
                break;
            case "HDOP":
                ret = this.Ext.EXT_POS_DATA.HDOP;
                break;
            case "PDOP":
                ret = this.Ext.EXT_POS_DATA.PDOP;
                break;
            case "SAT":
                ret = this.Ext.EXT_POS_DATA.SAT;
                break;
            case "MPSV":
                ret = this.Ext.STATE_DATA.MPSV;
                break;
            case "BBV":
                ret = this.Ext.STATE_DATA.BBV;
                break;
            case "IBV":
                ret = this.Ext.STATE_DATA.IBV;
                break;
        }
        return ret;
    }

    /**
     * вернет значение параметра по ключу с учетом формулы
     *
     * @param {TFormulaExpression} expr
     * @returns {number}
     */
    getValueByExpression(expr) {
        let ret = 0;
        const key = expr.token.name;
        const bit = expr.token.bit > 0 ? Number(expr.token.bit) : 0;
        const mask = (bit > 0) ? 1 << (bit - 1) : 0;
        if (simpleKeysValue.indexOf(key) !== -1) {
            ret = this.getValueSimple(key)
        } else {
            switch (key) {
                case "DIN":
                    if (bit > 0) {
                        ret = ((this.Din & mask) === mask) ? 1 : 0;
                    } else {
                        ret = this.Din;
                    }
                    break;
                case "SRC":
                    if (bit > 0) {
                        ret = ((this.Src & mask) === mask) ? 1 : 0;
                    } else {
                        ret = this.Src;
                    }
                    break;
                case "DOUT":
                    if (bit > 0) {
                        ret = ((this.Ext.AD_SENS_DATA.DOUT & mask) === mask) ? 1 : 0;
                    } else {
                        ret = this.Ext.AD_SENS_DATA.DOUT;
                    }
                    break;
                case "DIO":
                    if (bit > 0) {
                        ret = this.Ext.AD_SENS_DATA.DIO[bit - 1] || undefined;
                    }
                    break;
                case "ANS":
                    if (bit > 0) {
                        ret = this.Ext.AD_SENS_DATA.ANS[bit - 1] || undefined;
                        if (typeof ret === 'object' && ret !== undefined) {
                            if (typeof ret['flt'] !== "undefined") {
                                ret = Number(ret['flt'])
                            } else if (typeof ret['uint'] !== "undefined") {
                                ret = Number(ret['uint'])
                            } else if (typeof ret['int'] !== "undefined") {
                                ret = Number(ret['int'])
                            } else {
                                ret = 0
                            }
                        }
                    }
                    break;
                case "CAN":
                    if (bit > 0) {
                        ret = this.Ext.can.can[bit] || undefined;
                        if (typeof ret === 'object' && ret !== undefined) {
                            if (typeof ret['flt'] !== "undefined") {
                                ret = Number(ret['flt'])
                            } else if (typeof ret['uint'] !== "undefined") {
                                ret = Number(ret['uint'])
                            } else if (typeof ret['int'] !== "undefined") {
                                ret = Number(ret['int'])
                            } else {
                                ret = 0
                            }
                        }
                    }
                    break;
            }
        }
        return ret;
    }
}