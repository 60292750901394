export default {
    state: {
        authProcess: false
    },

    getters: {
        isAuth () {
            let login = localStorage.getItem('DISP_L')
            let password = localStorage.getItem('DISP_P')

            if (login && password) {
                return true
            }

            return false
        },
        authProcess: state => state.authProcess
    },

    actions: {
        auth ({ rootGetters }, cmd) {
            rootGetters.getSocket.send(cmd)
        }
    },
    mutations: {
        setAuthProcess (state, val) {
            state.authProcess = val
        }
    }
}