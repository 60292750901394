// import {TPoint} from "./point";
// import {TPointSimple} from "./point_simple";
import {TFormulaExpression} from "../formula/element";
import TFormula from "../formula/formula";

/**
 * Имя класса шаблона
 * @type {string}
 */
export const name = "point";

// Считает значения датчиков для объектов (точек)
export default class TPoints {

    // point: массив из 2-х точек (предыдущая и новая)
    // expr: массив с формулы для текущего объекта (точки) 
    eval(point, expr) {

        let a, b, rez = 0
        // is2;
        if (expr instanceof TFormulaExpression) {
            switch (expr.size) {
                // Два аргумента - бинарные операции.
                case 2:
                    a = this.eval(point, expr.a);
                    b = this.eval(point, expr.b);
                    if (a === undefined || b === undefined) {
                        rez = undefined
                        return
                    }
                    if (expr.token.name === "+") {
                        rez = a + b;
                    } else if (expr.token.name === "-") {
                        rez = a - b;
                    } else if (expr.token.name === "*") {
                        rez = a * b;
                    } else if (expr.token.name === "/") {
                        rez = a / b;
                    } else if (expr.token.name === "%") {
                        rez = a % b;
                    } else if (expr.token.name === "|") {
                        rez = a | b;
                    } else if (expr.token.name === "&") {
                        rez = a & b;
                    } else if (expr.token.name === "^") {
                        rez = a ^ b;
                    } else if (expr.token.name === ">") {
                        rez = (a > b) ? 1 : 0;
                    } else if (expr.token.name === "<") {
                        rez = (a < b) ? 1 : 0;
                    } else if (expr.token.name === "==") {
                        rez = (Number(a) === Number(b)) ? 1 : 0;
                    } else if (expr.token.name === "!=") {
                        rez = (Number(a) !== Number(b)) ? 1 : 0;
                    } else {
                        throw ("Unknown binary operator");
                    }
                    break;
                // Один аргумент.
                case 1:
                    a = this.eval(point, expr.a);
                    if (a === undefined) {
                        rez = undefined
                        return
                    }
                    if (expr.token.name === "+") {
                        rez = +a;
                    } else if (expr.token.name === "-") {
                        rez = -a;
                    } else if (expr.token.name === "!") {
                        rez = Number(!a);
                    } else {
                        rez = a;
                        //throw("Unknown unary operator");
                    }
                    break;
                //  параметр или датчик (ноль аргументов).
                case 0:
                    if (expr.token.role === "geozones") { // это geozone
                        // TODO [akopytenko]: Здесь будут геозоны
                        rez = undefined; // Заглушка
                    } else if (expr.token.role === "params") { // это параметр
                        if (expr.token.name === "CONST") {
                            rez = Number(expr.token.bit) || 0;
                        } else {
                            if (expr.token.pred === true) {
                                rez = point.o.getValueByExpression(expr);
                            } else {
                                rez = point.n.getValueByExpression(expr);
                            }
                        }
                    } else if (expr.token.role === "sensors") { // это датчик
                        // TODO [akopytenko]: Видимо, временное решение через поиск в browser storage 
                        const sensors = JSON.parse(sessionStorage.getItem('dispSensors'));
                        for (let i = 0; i < sensors.length; i++) {
                            let sensor = sensors[i]
                            if(sensor.uuid == expr.token.name) {
                                if (sensor.id !== 0) {
                                    const formula = new TFormula( sensor.ext.formula ).parse()
                                    rez = this.eval(point, formula);
                                }
                            }
                        }
                    }
                    break;
                default:
                    rez = "н/о"
            }
        } else {
            rez = "н/о"
        }
        return rez;
    }
}