import Vue from 'vue'
import Vuex from 'vuex'

import stateSocket      from './modules/socket'     // Socket
import stateUser        from './modules/user'       // Пользователя
import stateObjects     from './modules/objects'    // Автомобили
import statePoints      from './modules/points'     // Свойства автомобилей
import stateSensors     from './modules/sensors'    // Датчики автомобилей
import stateObject      from './modules/object'     // Состояние автомобиля
import stateTrack       from './modules/track'      // Маршрут автомобиля
import stateDiag        from './modules/diagnostic' // Диагностики автомобиля
import stateAuth        from './modules/auth'       // Авторизация

Vue.use(Vuex) 

export default new Vuex.Store({
    
    modules: {

        stateSocket,
        stateUser,
        stateObjects,
        statePoints,
        stateSensors,
        stateObject,
        stateTrack,
        stateDiag,
        stateAuth
    }
})