/**
 * единичное показание акселерометра
 * разрешающая способность полей ускорения - 0.01G.
 */
export class TPointSimpleExtraACCELAA {
    /**
     * @returns {number}
     */
    get RTM() {
        return this._RTM;
    }

    /**
     * @param {number} value
     */
    set RTM(value) {
        this._RTM = value;
    }

    /**
     * @returns {number}
     */
    get XAAV() {
        return this._XAAV;
    }

    /**
     * @param {number} value
     */
    set XAAV(value) {
        this._XAAV = value;
    }

    /**
     * @returns {number}
     */
    get YAAV() {
        return this._YAAV;
    }

    /**
     * @param {number} value
     */
    set YAAV(value) {
        this._YAAV = value;
    }

    /**
     * @returns {number}
     */
    get ZAAV() {
        return this._ZAAV;
    }

    /**
     * @param {number} value
     */
    set ZAAV(value) {
        this._ZAAV = value;
    }

    /**
     * @param {undefined|{RTM: number, XAAV: number, YAAV: number, ZAAV: number}} options
     */
    constructor(options) {
        /**
         * приращение к времени измерения предыдущей записи (для первой записи приращение к полю ATM), мс;
         * @type {number}
         * @private
         */
        this._RTM = 0;
        /**
         * значение линейного ускорения по оси X, м/с2 с дискретностью 0,1 м/с2;
         * @type {number}
         * @private
         */
        this._XAAV = 0;
        /**
         * значение линейного ускорения по оси Y, м/с2 с дискретностью 0,1 м/с2;
         * @type {number}
         * @private
         */
        this._YAAV = 0;
        /**
         * значение линейного ускорения по оси Z  м/с2 с дискретностью 0,1 м/с2;
         * @type {number}
         * @private
         */
        this._ZAAV = 0;
        if (options !== undefined) {
            this.add(options);
        }
    }

    /**
     * @param {{RTM: number, XAAV: number, YAAV: number, ZAAV: number}} d
     */
    add(d) {
        for (let key in d) {
            if (key in d) {
                switch (key) {
                    case 'RTM':
                        this.RTM = d[key];
                        break;
                    case 'XAAV':
                        this.XAAV = d[key];
                        break;
                    case 'YAAV':
                        this.YAAV = d[key];
                        break;
                    case 'ZAAV':
                        this.ZAAV = d[key];
                        break;
                }
            }
        }
    }

    /**
     * @returns {{RTM: number, XAAV: number, YAAV: number, ZAAV: number}}
     */
    valueOf() {
        return {
            RTM: this.RTM,
            XAAV: this.XAAV,
            YAAV: this.YAAV,
            ZAAV: this.ZAAV
        }
    }
}