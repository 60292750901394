import {TObjectExtra} from "./object_extra";

export class TObject {
    /**
     * @returns {TObjectExtra}
     */
    get ext() {
        return this._ext;
    }

    /**
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * @param {string} value
     */
    set name(value) {
        this._name = String(value);
    }

    /**
     * @returns {string}
     */
    get description() {
        return this._description;
    }

    /**
     * @param {string} value
     */
    set description(value) {
        this._description = String(value);
    }

    /**
     * @returns {number}
     */
    get idUser() {
        return this._idUser;
    }

    /**
     * @param {number} value
     */
    set idUser(value) {
        this._idUser = Number(value);
    }

    /**
     * @returns {number}
     */
    get idDevice() {
        return this._idDevice;
    }

    /**
     * @param {number} value
     */
    set idDevice(value) {
        this._idDevice = Number(value);
    }

    /**
     * @returns {number}
     */
    get online() {
        return this._online;
    }

    /**
     * @param {Object} value
     */
    set online(value) {
        this._online.value = value.id
        this._online.marker = value.marker
        this._online.descr = value.descr
    }

    /**
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * @param {number} value
     */
    set id(value) {
        this._id = Number(value);
    }

    /**
     * Считает статус объекта
     *
     * 0 - offline: нет связи более суток;      серый
     * 1 - offline: нет связи;                  красный
     * 2 - online:  есть связь, нет спутников;  жёлтый
     * 3 - online:  онлайн;                     зелёный
     *
     * @param {TPoint} p
     * @return {number}
     */
    calcOnline(p) {
        const UTC = (new Date()).getTime() / 1000;
        const diff = UTC - Math.max(p.n.Ntm, p.o.Ntm);
        if (diff > 86460) { // 60 * 60 * 24 + 60
            this._online = {
                value: 0,
                marker: '',
                descr: 'Неактивен'
            }
        } else if (diff > (this.ext.timeout * 60)) {
            this._online = {
                value: 1,
                marker: 'offline',
                descr: 'Нет связи'
            }
        } else {
            if (p.n.Ext.EXT_POS_DATA.SAT > 3) {
                this._online = {
                    value: 3,
                    marker: 'online',
                    descr: 'На связи'
                }
            } else {
                this._online = {
                    value: 2,
                    marker: 'noSputnik',
                    descr: 'Нет спутников'
                }
            }
        }
    }

    /**
     * @constructor
     * @param {{i: string, n: string, dsc: string, ext: {t: number, sc: string, fc: string, l: string}, u: string, d: string}|undefined} obj
     */
    constructor(obj) {
        /**
         * идентификатор
         * @type {number}
         * @private
         */
        this._id = 0;
        /**
         * название
         * @type {string}
         * @private
         */
        this._name = "";
        /**
         * Описание
         * @type {string}
         * @private
         */
        this._description = "";
        /**
         * идентификатор пользователя объекта
         * @type {number}
         * @private
         */
        this._idUser = 0;
        /**
         * идентификатор устройства
         * @type {number}
         * @private
         */
        this._idDevice = 0;
        /**
         * дополнительные данные
         * @type {TObjectExtra}
         * @private
         */
        this._ext = new TObjectExtra(undefined);
        /**
         * Online состояние объекта
         * @type {Object}
         * @private
         */
        this._online = {
            value: 0,
            marker: ''
        }

        if (obj !== undefined) {
            this.add(obj);
        }
    }

    clear() {
        this._name = "";
        this._description = "";
        this._idUser = 0;
        this._idDevice = 0;
        this._ext = new TObjectExtra(undefined);
    }

    /**
     *
     * @param {{i: string, n: string, dsc: string, ext: {t: number, sc: string, fc: string, l: string}, u: string, d: string}} d
     * @returns {TObject}
     */
    add(d) {
        this.clear();
        for (let key in d) {
            if (key in d) {
                switch (key) {
                    case "i":
                        this.id = d[key];
                        break;
                    case "n":
                        this.name = d[key];
                        break;
                    case "dsc":
                        this.description = d[key];
                        break;
                    case "ext":
                        this.ext.add(d[key]);
                        break;
                    case "u":
                        this.idUser = d[key];
                        break;
                    case "d":
                        this.idDevice = d[key];
                        break;
                }
            }
        }
        return this;
    }

    /**
     * @returns {{i: string, n: string, dsc: string, ext: {t: number, sc: string, fc: string, l: string}, u: string, d: string}}
     */
    valueOf() {
        return {
            i: String(this.id),
            n: this.name,
            dsc: this.description,
            ext: this.ext.valueOf(),
            u: String(this.idUser),
            d: String(this.idDevice)
        };
    }

    toString() {
        return JSON.stringify(this.valueOf());
    }
}