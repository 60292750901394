export default {

    state: {

        stateUser: null,
        userMsg: null
    },

    getters: {

        getUser: state => state.stateUser,
        getUserMsg: state => state.userMsg
    },

    actions: {

        addUser ( {state}, user = false) {

            user ? state.stateUser = user : state.stateUser = null
        }
    },

    mutations: {

        updateUserMsg(state, msg) {

            state.userMsg = msg
        }
    }
}