/**
 * дискретные и аналоговые входы/выходы
 */
export class TPointSimpleExtraCAN {
    /**
     *
     * @returns {{string: {}}}
     */
    get can() {
        return this._can;
    }

    /**
     *
     * @param {{string: {}}} value
     */
    set can(value) {
        this._can = value;
    }


    /**
     * @param {undefined|{can: {string: {}}}} options
     */
    constructor(options) {
        /**
         *
         * @type {{string: {}}}
         * @private
         */
        this._can = {};
        if (options !== undefined) {
            this.add(options);
        }
    }

    /**
     * @param {{can: {string: {}}}} d
     */
    add(d) {
        for (let key in d) {
            if (key in d) {
                switch (key) {
                    // значение аналоговых датчиков
                    case 'can':
                        this.can = d[key];
                        break;
                }
            }
        }
    }

    /**
     *
     * @returns {{can: {string: {}}}}
     */
    valueOf() {
        return {
            can: this.can,
        }
    }
}