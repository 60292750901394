export default {

    state: {

        stateSocket: {},
        socketIsLoading: true
    },

    getters: {

        getSocket: state => state.stateSocket,
        getSocketIsLoading: state => state.socketIsLoading
    },

    mutations: {
        setSocketIsLoading(state, val) {
            state.socketIsLoading = val
        }
    },

    actions: {

        addSocket ( {state} , socket) {

            state.stateSocket = socket
        }
    }
}