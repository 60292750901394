import { TObject } from '@/helpers/objects/object'

export default {

    state: {

        stateObjects: false
    },

    getters: {

        getObjects: state => state.stateObjects
    },

    actions: {

        addObjects ({state}, objects) {

            let dispObjects = objects.map( obj => new TObject(obj) )

            dispObjects.sort(function(a, b) {
                if (a.name > b.name) return 1
                if (a.name < b.name) return -1
                return 0
            })

            state.stateObjects = dispObjects
        }
    }
}